<template>
  <div>
    <div class="guangdong">
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        class="demo-form-inline"
        :inline="true"
      >
        <el-form-item>
          <el-input v-model="form.idcard" placeholder="身份证号码"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSelect">查询</el-button>
        <el-button type="primary" @click="onUpdate">更新</el-button>
      </el-form>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column label="省平台" align="center" prop="photo">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.photo_base"
              fit="fill"
            />
          </template>
        </el-table-column>
        <el-table-column label="车学堂" align="center" prop="cxtimg">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.cxtimg_base"
              fit="fill"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { guangdong, updateImg } from '../api/user'

export default {
  data() {
    return {
      form: {
        idcard: ''
      },
      tableData: []
    }
  },
  methods: {
    onSelect() {
      if (this.form.idcard == '') {
        this.$message({
          type: 'warning',
          message: '查询条件不能为空'
        })
        return
      }
      guangdong(this.form).then(res => {
        if (res.code == 0) {
          this.tableData = res.data
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      })
    },
    onUpdate() {
      let params = {
        photo: this.tableData[0]['photo'],
        user_id: this.tableData[0]['user_id']
      }
      updateImg(params).then(res => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '更新成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: '更新失败'
          })
        }
      })
    }
  },
  computed: {
    activeName() {
      return steps[this.active]
    }
  }
}
</script>
<style>
.guangdong {
  margin: 10px;
  padding: 50px 400px 20px 400px;
  text-align: center;
}
</style>
